#wrapper.pt_cart #main #primary #cart-items-form .cart-footer .action-carousel .cart-order-totals {
	.japan-tax-vat,
	.japan-tax-number {
		display: none !important;
	}
}


.cart-actions-top {
    .cart-action-checkout {
        width: 100%;
        float: none;
    }

    .cart-actions-top-buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        flex-wrap: wrap;
        @media (max-width: 767px) {
            display: flex;
            flex-direction: column-reverse;
        }

        button.go-checkout {
            height: 33.56px;
            float: none !important;
            margin: 0;
            @media (min-width: 768px) {
                margin: 0 0 0 15px;
            }
        }

        .amazon-pay-button-component {
            @media screen and (min-width: 992px) {
                width: 250px !important;
                margin-top: 2px;
            }
            @media screen and (min-width: 768px) and (max-width: 991px) {
                width: 190px !important;
            }
            @media (max-width: 767px) {
                margin-top: 10px;
            }
        }

        .cart-action-paypal {
            height: 33.56px;
            @media (min-width: 768px) {
                margin: 0 0 0 15px !important;
            }
        }
    }
}

.cart-actions {
    .amazon-pay-button-component {
        @media (max-width: 767px) {
            margin-top: 10px;
        }
    }
}

.cart-actions-another {
    margin-top: 0 !important;
    float: none !important;
    text-align: right;
    clear: both;

    .cart-payment-bottom {
        display: flex;
        flex-direction: row;
        float: none;
        width: 100%;
        justify-content: flex-end;
        @media (max-width: 767px) {
            flex-direction: column-reverse;
        }
    }

    .cart-action-paypal {
        height: 33.56px;
        margin: 0 0 0 15px !important;
        @media (max-width: 767px) {
            margin: 0 0 10px 0 !important;
        }
    }

    .amazon-pay-button-component {
        margin-top: 0;
    }

    #amazon-pay-cart-one-time-button-another {
        @media screen and (min-width: 768px) {
            width: 230px !important;
            margin: 0 0 0 auto;
        }
    }
}
