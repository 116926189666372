.configurator-form:not(.configurator-color) {
    width: calc(100% + 6px);
    margin-left: -6px;
    &.configurator-regular {
        justify-content: flex-start;
        column-gap: 0;
        .configurator-option {
            padding: 0 0 6px 6px;
            flex: 0 0 33.33%;
        }

        &.configurator-number {
            .configurator-option {
                padding: 0 0 6px 6px;
                flex: 0 0 auto;

                label {
                    padding: 0 23px;
                    box-sizing: border-box;
                }
            }
        }
    }
}
