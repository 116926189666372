/////////////////////////
// Checkout Shipping Step
/////////////////////////

#wrapper.pt_checkout {
    #main {
        #primary {
            //class ends with "addressFields_postal"
            .form-row[class$="addressFields_postal"] {

                .field-wrapper {
                    @include respond(largeUp) {
                        max-width: calc(100% - 190px);
                    }
                }
                
                .melisa-data-button {
                    height: 44px;
                    margin-top: 10px;
                    width: 100%;

                    @include respond(largeUp) {
                        position: absolute;
                        top: 20px;
                        right: 0;
                        width: auto;
                        padding: 0.5em 3em;
                        height: auto;
                    }
                }
            }

            .checkout-shipping {
                h2.email-header {
                    margin-bottom: 12px;
                }
            }

            .checkout-billing {
                .fieldset.address {
                    &.open {
                        @include respond(largeUp) {
                            margin: 25px 0 48px;
                        }
                    }
                    
                    .form-row {
                        label {
                            position: static;
                            line-height: 1.7;
                        }
                    }
                }
            }

            .login-box .login-box-content {
                .form-row.form-row-button #password-reset {
                    margin-right: 14px;
                }
            }
        }
    }
}
