#wrapper.pt_checkout #main #secondary .checkout-order-totals {
	.japan-tax-vat,
	.japan-tax-number {
		display: none !important;
	}
}

.confirmation-shipping-attribute,
.confirmation-shipping-attribute-another {
    display: none !important;
}
