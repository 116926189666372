.account__column--form-login {
    @include respond(standard) {
        padding: 48px 50px 82px 60px;
    }
}

.ui-dialog.ui-widget {
    #dialog-container{
        #edit-address-form{
            .form-row[class$="address_postal"]  {
                position: relative;

                .field-wrapper {
                    @include respond(largeUp) {
                        max-width: calc(100% - 190px);
                    }
                }

                .melisa-data-button {
                    width: 100%;

                    @include respond(largeUp) {
                        position: absolute;
                        top: 18px;
                        right: 0;
                        width: auto;
                        margin-right: 0 !important;
                        padding: 0.5em 3em;
                    }
                }
            }

            .btn-secondary {
                background: $buttonGrey;
                border-color: $buttonGrey;
            }
        }
    }
}

#wrapper.pt_account #primary .account-wrapper  {
    .password .password__show-hide {
        top: 36px;
    }
}

.form__gender {
    .field-wrapper { 
        flex-wrap: nowrap;

        &__radio-button {
            display: flex;
            flex-direction: column;

            &__input:checked ~ .field-wrapper__radio-button__label {
                color: $white;
                background: $black;
            }

            span.error {
                order: 1;
                white-space: nowrap;
            }
        }
    }
}