.customer-service-mobile-drawer {
	display: none;
}

.customer-service-contact {
	display: none;
	@include respond(small) {
		padding-left: 3%;
		margin: 1.5rem 0;
	}
	.contact  {
		h3 {
			font-size: 1rem;
			margin-bottom: 1em;
			line-height: 1;
			text-transform: none;
			color: $black;
			font-weight: 400;
		}
		p {
			font-size: 14px;
			line-height: 1.5;
		}
		span {
			font-size: 12px;
		}
	}
}

#wrapper.pt_customer-service {
	.required-indicator {
		color: #4c4c4c;
	}
	h1 {
		margin-top: 3rem;
	}
	#secondary {
		display: none;
	}
	#primary {
		&.primary-content {
			width: 600px;
			float: none;
			@include respond(small) {
				float: none;
				width: 96%;
			}
		}
		.form-row {
			label {
				font-weight: 700;
				text-transform: uppercase;
				.required-indicator {
					display: none;
				}
			}
			&.required label {
				&::after {
					content: '*';
					color: #4c4c4c;
				}
			}
		}
	}
}


.contactus-form {
    max-width: 490px;

    .input-textarea {
        width: 100%;
        border-radius: 2em;
    }

    .form-row-button {
        width: 100%;
        text-align: right;
    }

    #sendBtn {
        display: inline-block;
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
        line-height: 1.2;
        padding: 0.45em 3em;
        border-radius: 2rem;
        transform: translateZ(0);
        -webkit-appearance: none;
        cursor: pointer;
        text-align: center;
        outline: none;
        border: none;
        text-decoration: none;
        @include redButton;
    }
}
