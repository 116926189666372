#wrapper.pt_checkout #main #primary {
    .checkout-billing .fieldset > .payment-method-options.form-indent .form-row label {
        max-width: 120px;
    }

    .billing-coupon-code {
        .form-row.label-above {
            @include respond(small) {
                display: block;
                float: none;
                width: auto;
            }
        }
        button#add-coupon {
            position: relative;
            top: 8px;
            @include respond(small) {
                float: none;
            }
        }
    }
}